/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuizAsset,
    QuizAssetFromJSON,
    QuizAssetFromJSONTyped,
    QuizAssetToJSON,
    QuizOption,
    QuizOptionFromJSON,
    QuizOptionFromJSONTyped,
    QuizOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuizSubject
 */
export interface QuizSubject {
    /**
     * 
     * @type {number}
     * @memberof QuizSubject
     */
    quizId: number;
    /**
     * 
     * @type {number}
     * @memberof QuizSubject
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof QuizSubject
     */
    prompt: string;
    /**
     * 
     * @type {Array<QuizOption>}
     * @memberof QuizSubject
     */
    options: Array<QuizOption>;
    /**
     * 
     * @type {number}
     * @memberof QuizSubject
     */
    answers: number;
    /**
     * 
     * @type {Array<QuizAsset>}
     * @memberof QuizSubject
     */
    assets: Array<QuizAsset>;
    /**
     * 
     * @type {Array<QuizAsset>}
     * @memberof QuizSubject
     */
    references: Array<QuizAsset>;
}

export function QuizSubjectFromJSON(json: any): QuizSubject {
    return QuizSubjectFromJSONTyped(json, false);
}

export function QuizSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuizSubject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quizId': json['quizId'],
        'score': json['score'],
        'prompt': json['prompt'],
        'options': ((json['options'] as Array<any>).map(QuizOptionFromJSON)),
        'answers': json['answers'],
        'assets': ((json['assets'] as Array<any>).map(QuizAssetFromJSON)),
        'references': ((json['references'] as Array<any>).map(QuizAssetFromJSON)),
    };
}

export function QuizSubjectToJSON(value?: QuizSubject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quizId': value.quizId,
        'score': value.score,
        'prompt': value.prompt,
        'options': ((value.options as Array<any>).map(QuizOptionToJSON)),
        'answers': value.answers,
        'assets': ((value.assets as Array<any>).map(QuizAssetToJSON)),
        'references': ((value.references as Array<any>).map(QuizAssetToJSON)),
    };
}


