/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Taxonomy,
    TaxonomyFromJSON,
    TaxonomyFromJSONTyped,
    TaxonomyToJSON,
    TaxonomyBrief,
    TaxonomyBriefFromJSON,
    TaxonomyBriefFromJSONTyped,
    TaxonomyBriefToJSON,
} from './';

/**
 * 
 * @export
 * @interface TaxonomyPage
 */
export interface TaxonomyPage {
    /**
     * 
     * @type {TaxonomyBrief}
     * @memberof TaxonomyPage
     */
    preceding?: TaxonomyBrief;
    /**
     * 
     * @type {Taxonomy}
     * @memberof TaxonomyPage
     */
    current?: Taxonomy;
    /**
     * 
     * @type {TaxonomyBrief}
     * @memberof TaxonomyPage
     */
    succeding?: TaxonomyBrief;
    /**
     * 
     * @type {Array<TaxonomyBrief>}
     * @memberof TaxonomyPage
     */
    ancestors?: Array<TaxonomyBrief>;
    /**
     * 
     * @type {Array<TaxonomyBrief>}
     * @memberof TaxonomyPage
     */
    children?: Array<TaxonomyBrief>;
}

export function TaxonomyPageFromJSON(json: any): TaxonomyPage {
    return TaxonomyPageFromJSONTyped(json, false);
}

export function TaxonomyPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'preceding': !exists(json, 'preceding') ? undefined : TaxonomyBriefFromJSON(json['preceding']),
        'current': !exists(json, 'current') ? undefined : TaxonomyFromJSON(json['current']),
        'succeding': !exists(json, 'succeding') ? undefined : TaxonomyBriefFromJSON(json['succeding']),
        'ancestors': !exists(json, 'ancestors') ? undefined : ((json['ancestors'] as Array<any>).map(TaxonomyBriefFromJSON)),
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(TaxonomyBriefFromJSON)),
    };
}

export function TaxonomyPageToJSON(value?: TaxonomyPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'preceding': TaxonomyBriefToJSON(value.preceding),
        'current': TaxonomyToJSON(value.current),
        'succeding': TaxonomyBriefToJSON(value.succeding),
        'ancestors': value.ancestors === undefined ? undefined : ((value.ancestors as Array<any>).map(TaxonomyBriefToJSON)),
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(TaxonomyBriefToJSON)),
    };
}


