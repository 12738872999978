import {action, createModule} from "vuex-class-component";
import {
    Configuration,
    DefaultApi, GetListDataRequest, ListPortraitsIdRequest, LoadTaxonomyRequest,
    TaxonomyBrief,
    TaxonomyList, TaxonomyPage, TaxonomyPortraitNode
} from "@/service";
import Vue from "vue";

import {
    castTaxonomyToBrief,
    DefaultTaxonomyBrief,
    DefaultTaxonomyList, DefaultTaxonomyPage, TaxonomyQuery,
} from "@/types";
import {vxm} from "@/store/index";

const VuexModule = createModule({
    namespaced: "taxonomyStore",
    strict: false,
    // target: "nuxt",
})

const SHOW_DRAWER_KEY = "showDrawer";
const SHOW_TREE_MODE_KEY = "treeMode";


export class TaxonomyStore extends VuexModule {

    private service = new DefaultApi(new Configuration({
        basePath: process.env.VUE_APP_SERVICE_BASE_URL,
    }));


    // contain all Taxonomy
    public listData: TaxonomyList = DefaultTaxonomyList;
    public treeRoot: TaxonomyBrief = DefaultTaxonomyBrief;
    public allSpecies: TaxonomyBrief[] = [];
    public allPortraits: TaxonomyPortraitNode[] = [];
    public taxonomyPage: TaxonomyPage = DefaultTaxonomyPage;

    public opened: number[] = [];

    @action
    async fetchTaxonomy(query: LoadTaxonomyRequest): Promise<TaxonomyPage> {

        return this.service.loadTaxonomy(query)
            .then(function (json) {
                const store = vxm.taxonomyStore;
                store.taxonomyPage.ancestors = json.ancestors?.reverse();
                if (json.children) {
                    store.taxonomyPage.children = json.children;
                } else {
                    store.taxonomyPage.children?.splice(0, store.taxonomyPage.children?.length);
                }

                if (json.current) {
                    store.taxonomyPage.current = json.current;
                }

                return json;

            }, function (error) {
                vxm.main.onError(`loadTaxonomy API: ${error}`)
                return error;
            })
    }

    @action
    async fetchTaxonomyChildren(query: TaxonomyQuery): Promise<TaxonomyPage> {

        return this.service.loadTaxonomy({
            listId: query.list, nodeId: query.node, fields: ['children']
        }).then(function (page) {
            // vxm.taxonomyStore.treeRoot = Object.assign(page.current, {children: page.children});
            query.target.children = page.children;
            return page.children;
        }, function (error) {
            vxm.main.onError(`loadTaxonomy API: ${error}`)
            return error;
        })
    }

    @action
    async loadAllSpecies(listId: number): Promise<Array<TaxonomyBrief>> {

        const key = `allSpecies_${listId}`;

        if (Vue.$storage.has(key)) {
            vxm.taxonomyStore.allSpecies = Vue.$storage.get(key, []);
            return vxm.taxonomyStore.allSpecies;
        } else {
            return this.service.listAllSpecies({listId: +listId})
                .then(function (speciesArray) {
                    vxm.taxonomyStore.allSpecies = speciesArray;
                    Vue.$storage.set(key, speciesArray);
                }, function (error) {
                    vxm.main.onError(`loadAllSpecies API: ${error}`)
                    return error;
                })
        }
    }

    @action
    async loadTreeRoot(listId: number): Promise<TaxonomyPage> {
        return this.service.loadTaxonomy({
            listId: listId, nodeId: 0, fields: ['self', 'children']
        }).then(function (page) {
            vxm.taxonomyStore.treeRoot = Object.assign(castTaxonomyToBrief(page.current),
                {children: page.children}
            );
            return page;
        }, function (error) {
            vxm.main.onError(`loadTreeRoot API: ${error}`)
            return error;
        })
    }

    @action
    async loadListData(params: GetListDataRequest): Promise<TaxonomyList> {

        return this.service.getListData(params)
            .then(function (listData) {
                vxm.taxonomyStore.listData = listData;
                return listData;
            }, function (error) {
                vxm.main.onError(`loadListData API: ${error}`)
                return error;
            })
    }

    @action
    async loadListPortraits(params: ListPortraitsIdRequest): Promise<Array<TaxonomyPortraitNode>> {

        return this.service.listPortraitsId(params)
            .then(function (listData) {
                vxm.taxonomyStore.allPortraits = listData;
                return listData;
            }, function (error) {
                vxm.main.onError(`loadListPortraits API: ${error}`)
                return error;
            })
    }

    @action
    async onListPage(listId: number): Promise<TaxonomyList> {
        if (this.listData.id != listId) {
            await this.loadListData({listId: listId});

            if (this.treeMode) {
                await this.loadTreeRoot(listId);
            } else {
                await this.loadAllSpecies(listId);
            }

            await this.loadListPortraits({listId: listId});
        }

        return this.listData;
    }

    private _showDrawer: boolean = Vue.$storage.get(SHOW_DRAWER_KEY, true)

    public get showDrawer(): boolean {
        // if (undefined == this._showDrawer) {
        //     let showDrawer = true;
        //     if (Vue.$storage.has(SHOW_DRAWER_KEY)) {
        //         showDrawer = Vue.$storage.get(SHOW_DRAWER_KEY, true)
        //     } else {
        //         Vue.$storage.set(SHOW_DRAWER_KEY, showDrawer)
        //     }
        //     this._showDrawer = showDrawer
        //     return showDrawer;
        // } else {
            return this._showDrawer;
        // }
    }

    public set showDrawer(v: boolean) {
        this._showDrawer = v
        Vue.$storage.set(SHOW_DRAWER_KEY, this._showDrawer)
    }

    // Use undefined to determine that need fetch from storage.
    private _treeMode?: boolean
    public get treeMode(): boolean {
        if (undefined == this._treeMode) {
            let treeMode = false;
            if (Vue.$storage.has(SHOW_TREE_MODE_KEY)) {
                treeMode = Vue.$storage.get(SHOW_TREE_MODE_KEY, false)
            } else {
                Vue.$storage.set(SHOW_TREE_MODE_KEY, treeMode)
            }
            this._treeMode = treeMode;
            return treeMode;
        } else {
            return this._treeMode;
        }
    }

    public set treeMode(v: boolean) {
        this._treeMode = v
        Vue.$storage.set(SHOW_TREE_MODE_KEY, this._treeMode)
    }


}
