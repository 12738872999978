/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuizSubject,
    QuizSubjectFromJSON,
    QuizSubjectFromJSONTyped,
    QuizSubjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuizPaper
 */
export interface QuizPaper {
    /**
     * 
     * @type {number}
     * @memberof QuizPaper
     */
    level: number;
    /**
     * 
     * @type {Array<QuizSubject>}
     * @memberof QuizPaper
     */
    subjects: Array<QuizSubject>;
}

export function QuizPaperFromJSON(json: any): QuizPaper {
    return QuizPaperFromJSONTyped(json, false);
}

export function QuizPaperFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuizPaper {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': json['level'],
        'subjects': ((json['subjects'] as Array<any>).map(QuizSubjectFromJSON)),
    };
}

export function QuizPaperToJSON(value?: QuizPaper | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': value.level,
        'subjects': ((value.subjects as Array<any>).map(QuizSubjectToJSON)),
    };
}


