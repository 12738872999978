import Vue from 'vue';
import Vuex from 'vuex';

import {Vue2Storage} from 'vue2-storage';

Vue.use(Vue2Storage, {
        prefix: 'xmbirds_',
        driver: 'local',
        ttl: 60 * 60 * 24 * 1000 * 10, // 10 days
    }
);


import {createModule, mutation, extractVuexModule, createProxy, Module} from "vuex-class-component";
import {
    DefaultSnackbar,
    SnackBar,
} from "@/types";
import router from "@/router";
import {Route} from "vue-router";
import {Configuration, DefaultApi} from "@/service";
import {PortraitsStore} from "@/store/portrait";
import {MediaStore} from "@/store/media";
import {TaxonomyStore} from "@/store/taxonomy";


Vue.use(Vuex);

const VuexModule = createModule({
    namespaced: "main",
    strict: false,
    // target: "nuxt",
})

// @Module({namespacedPath: "main"})
export class MainStore extends VuexModule {

    public mediaUrlBase = process.env.VUE_APP_MEDIA_BASE_URL;

    private service = new DefaultApi(new Configuration({
        basePath: process.env.VUE_APP_SERVICE_BASE_URL,
    }));

    public loading = false;

    public snackbar: SnackBar = DefaultSnackbar;

    public get route(): Route {
        return router.currentRoute;
    }

    @mutation
    onSuccess(payload: string): void {
        this.snackbar.now = true;
        this.snackbar.text = payload;
        this.snackbar.color = 'success';
    }

    @mutation
    onError(payload: string): void {
        this.snackbar.now = true;
        this.snackbar.text = payload;
        this.snackbar.color = 'error';
    }
}

// store.vuex.ts
export const store = new Vuex.Store({
    modules: {
        ...extractVuexModule(MainStore),
        ...extractVuexModule(TaxonomyStore),
        ...extractVuexModule(MediaStore),
        ...extractVuexModule(PortraitsStore)
    }
})


// Creating proxies.
export const vxm = {
    main: createProxy(store, MainStore),
    taxonomyStore: createProxy(store, TaxonomyStore),
    mediaModule: createProxy(store, MediaStore),
    portraitsModule: createProxy(store, PortraitsStore)
}
