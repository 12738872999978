/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TaxonomyPresence,
    TaxonomyPresenceFromJSON,
    TaxonomyPresenceFromJSONTyped,
    TaxonomyPresenceToJSON,
    TaxonomyPresenceTraitsAllOf,
    TaxonomyPresenceTraitsAllOfFromJSON,
    TaxonomyPresenceTraitsAllOfFromJSONTyped,
    TaxonomyPresenceTraitsAllOfToJSON,
    TaxonomyTrait,
    TaxonomyTraitFromJSON,
    TaxonomyTraitFromJSONTyped,
    TaxonomyTraitToJSON,
} from './';

/**
 * 
 * @export
 * @interface TaxonomyPresenceTraits
 */
export interface TaxonomyPresenceTraits {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPresenceTraits
     */
    presenceId: number;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyPresenceTraits
     */
    scope?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyPresenceTraits
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPresenceTraits
     */
    origin: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPresenceTraits
     */
    media: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPresenceTraits
     */
    taxonomy: number;
    /**
     * 
     * @type {Date}
     * @memberof TaxonomyPresenceTraits
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TaxonomyPresenceTraits
     */
    updatedAt: Date;
    /**
     * 
     * @type {Array<TaxonomyTrait>}
     * @memberof TaxonomyPresenceTraits
     */
    traits: Array<TaxonomyTrait>;
}

export function TaxonomyPresenceTraitsFromJSON(json: any): TaxonomyPresenceTraits {
    return TaxonomyPresenceTraitsFromJSONTyped(json, false);
}

export function TaxonomyPresenceTraitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyPresenceTraits {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presenceId': json['presenceId'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'origin': json['origin'],
        'media': json['media'],
        'taxonomy': json['taxonomy'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'traits': ((json['traits'] as Array<any>).map(TaxonomyTraitFromJSON)),
    };
}

export function TaxonomyPresenceTraitsToJSON(value?: TaxonomyPresenceTraits | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'presenceId': value.presenceId,
        'scope': value.scope,
        'note': value.note,
        'origin': value.origin,
        'media': value.media,
        'taxonomy': value.taxonomy,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'traits': ((value.traits as Array<any>).map(TaxonomyTraitToJSON)),
    };
}


