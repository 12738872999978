/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxonomyData
 */
export interface TaxonomyData {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyData
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyData
     */
    list: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyData
     */
    node: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyData
     */
    taxonomy: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyData
     */
    originalId?: number;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyData
     */
    latin: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyData
     */
    english?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyData
     */
    chinese?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyData
     */
    traditional?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyData
     */
    authority?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyData
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyData
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyData
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyData
     */
    aliasId?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyData
     */
    endangered?: string;
}

export function TaxonomyDataFromJSON(json: any): TaxonomyData {
    return TaxonomyDataFromJSONTyped(json, false);
}

export function TaxonomyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'list': json['list'],
        'node': json['node'],
        'taxonomy': json['taxonomy'],
        'originalId': !exists(json, 'originalId') ? undefined : json['originalId'],
        'latin': json['latin'],
        'english': !exists(json, 'english') ? undefined : json['english'],
        'chinese': !exists(json, 'chinese') ? undefined : json['chinese'],
        'traditional': !exists(json, 'traditional') ? undefined : json['traditional'],
        'authority': !exists(json, 'authority') ? undefined : json['authority'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'aliasId': !exists(json, 'aliasId') ? undefined : json['aliasId'],
        'endangered': !exists(json, 'endangered') ? undefined : json['endangered'],
    };
}

export function TaxonomyDataToJSON(value?: TaxonomyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'list': value.list,
        'node': value.node,
        'taxonomy': value.taxonomy,
        'originalId': value.originalId,
        'latin': value.latin,
        'english': value.english,
        'chinese': value.chinese,
        'traditional': value.traditional,
        'authority': value.authority,
        'url': value.url,
        'code': value.code,
        'note': value.note,
        'aliasId': value.aliasId,
        'endangered': value.endangered,
    };
}


