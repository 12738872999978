/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TaxonomyMedia,
    TaxonomyMediaFromJSON,
    TaxonomyMediaFromJSONTyped,
    TaxonomyMediaToJSON,
    TaxonomyPortraitAllOf,
    TaxonomyPortraitAllOfFromJSON,
    TaxonomyPortraitAllOfFromJSONTyped,
    TaxonomyPortraitAllOfToJSON,
    TaxonomyPresenceTraits,
    TaxonomyPresenceTraitsFromJSON,
    TaxonomyPresenceTraitsFromJSONTyped,
    TaxonomyPresenceTraitsToJSON,
} from './';

/**
 * 
 * @export
 * @interface TaxonomyPortrait
 */
export interface TaxonomyPortrait {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPortrait
     */
    mediaId: number;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyPortrait
     */
    md5: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyPortrait
     */
    contentType?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyPortrait
     */
    originName?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyPortrait
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyPortrait
     */
    copyright?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPortrait
     */
    fileSize: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPortrait
     */
    duration?: number;
    /**
     * 
     * @type {Date}
     * @memberof TaxonomyPortrait
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TaxonomyPortrait
     */
    updatedAt: Date;
    /**
     * 
     * @type {Array<TaxonomyPresenceTraits>}
     * @memberof TaxonomyPortrait
     */
    presences: Array<TaxonomyPresenceTraits>;
}

export function TaxonomyPortraitFromJSON(json: any): TaxonomyPortrait {
    return TaxonomyPortraitFromJSONTyped(json, false);
}

export function TaxonomyPortraitFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyPortrait {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mediaId': json['mediaId'],
        'md5': json['md5'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'originName': !exists(json, 'originName') ? undefined : json['originName'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'copyright': !exists(json, 'copyright') ? undefined : json['copyright'],
        'fileSize': json['fileSize'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'presences': ((json['presences'] as Array<any>).map(TaxonomyPresenceTraitsFromJSON)),
    };
}

export function TaxonomyPortraitToJSON(value?: TaxonomyPortrait | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mediaId': value.mediaId,
        'md5': value.md5,
        'contentType': value.contentType,
        'originName': value.originName,
        'author': value.author,
        'copyright': value.copyright,
        'fileSize': value.fileSize,
        'duration': value.duration,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'presences': ((value.presences as Array<any>).map(TaxonomyPresenceTraitsToJSON)),
    };
}


