/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuizAsset,
    QuizAssetFromJSON,
    QuizAssetFromJSONTyped,
    QuizAssetToJSON,
    QuizMarker,
    QuizMarkerFromJSON,
    QuizMarkerFromJSONTyped,
    QuizMarkerToJSON,
    QuizReferenceAllOf,
    QuizReferenceAllOfFromJSON,
    QuizReferenceAllOfFromJSONTyped,
    QuizReferenceAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuizReference
 */
export interface QuizReference {
    /**
     * 
     * @type {string}
     * @memberof QuizReference
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof QuizReference
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof QuizReference
     */
    copyright?: string;
    /**
     * 
     * @type {number}
     * @memberof QuizReference
     */
    presence?: number;
    /**
     * 
     * @type {string}
     * @memberof QuizReference
     */
    species: string;
    /**
     * 
     * @type {Array<QuizMarker>}
     * @memberof QuizReference
     */
    markers: Array<QuizMarker>;
}

export function QuizReferenceFromJSON(json: any): QuizReference {
    return QuizReferenceFromJSONTyped(json, false);
}

export function QuizReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuizReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'copyright': !exists(json, 'copyright') ? undefined : json['copyright'],
        'presence': !exists(json, 'presence') ? undefined : json['presence'],
        'species': json['species'],
        'markers': ((json['markers'] as Array<any>).map(QuizMarkerFromJSON)),
    };
}

export function QuizReferenceToJSON(value?: QuizReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'author': value.author,
        'copyright': value.copyright,
        'presence': value.presence,
        'species': value.species,
        'markers': ((value.markers as Array<any>).map(QuizMarkerToJSON)),
    };
}


