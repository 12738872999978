import {RawLocation} from "vue-router";
import Vue from "vue";

export type RouteNext = (to?: RawLocation | false | ((vm: Vue) => any) | void) => void;

export interface SnackBar {
    text: string;
    now?: boolean;
    color?: string;
}


export const DefaultSnackbar = {
    now: false,
    text: '',
    color: 'success'
}

export * from "@/types/taxonomy";
