/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuizMarker
 */
export interface QuizMarker {
    /**
     * 
     * @type {number}
     * @memberof QuizMarker
     */
    marker: number;
    /**
     * 
     * @type {string}
     * @memberof QuizMarker
     */
    points: string;
    /**
     * 
     * @type {string}
     * @memberof QuizMarker
     */
    trait: string;
}

export function QuizMarkerFromJSON(json: any): QuizMarker {
    return QuizMarkerFromJSONTyped(json, false);
}

export function QuizMarkerFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuizMarker {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'marker': json['marker'],
        'points': json['points'],
        'trait': json['trait'],
    };
}

export function QuizMarkerToJSON(value?: QuizMarker | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'marker': value.marker,
        'points': value.points,
        'trait': value.trait,
    };
}


