import {action, createModule, mutation} from "vuex-class-component";
import {
  Configuration, CreatePresenceRequest, CreateTraitRequest,
  DefaultApi, DeletePresenceRequest, DeleteTraitRequest, ListNodePortraitsRequest,
  ListTaxonomyPortraitsRequest,
  TaxonomyPortrait,
  TaxonomyPresenceTraits, TaxonomyTrait, UpdateTraitRequest
} from "@/service";
import {vxm} from "@/store/index";
import router from "@/router";
import Vue from "vue";
import {DefaultTaxonomyPortrait} from "@/types/portraits";

import * as d3 from "d3";

const VuexModule = createModule({
  namespaced: "portraitStore",
  strict: false,
  // target: "nuxt",
})


export class PortraitsStore extends VuexModule {

  private service = new DefaultApi(new Configuration({
    basePath: process.env.VUE_APP_SERVICE_BASE_URL,
  }));

  public portraits: TaxonomyPortrait[] = [];

  public currentPortraitIndex = 0;
  public currentPortrait: TaxonomyPortrait = DefaultTaxonomyPortrait;
  public currentTraits: TaxonomyTrait[] = [];
  public currentTraitColors: string[] = [];

  @action
  async listTaxonomyPortraits(query: ListTaxonomyPortraitsRequest) {

    this.service.listTaxonomyPortraits(query)
      .then(function (portraits) {
        vxm.portraitsModule.onPortraits(portraits);
      }, function (error) {
        vxm.main.onError(`listTaxonomyPortraits API: ${error}`)
      })
  }

  @action
  async listNodePortraits(query: ListNodePortraitsRequest) {

    this.service.listNodePortraits(query)
      .then(function (portraits) {
        vxm.portraitsModule.onPortraits(portraits);
      }, function (error) {
        vxm.main.onError(`listNodePortraits API: ${error}`)
      })
  }


  @action
  async createPresence(request: CreatePresenceRequest) {
    return this.service.createPresence(request)
      .then(function (portrait) {
        vxm.main.onSuccess(`图片中增加新的鸟种特征成功！`);
        vxm.portraitsModule.onPortraitUpdate(portrait);
      }, function (error) {
        vxm.main.onError(`createPresence API: ${error}`)
      })
  }

  @action
  async deletePresence(request: DeletePresenceRequest) {
    return this.service.deletePresence(request)
      .then(function (portrait) {
        vxm.portraitsModule.onPortraitUpdate(portrait);
        vxm.main.onSuccess(`图片中删除鸟种特征成功！`);
      }, function (error) {
        vxm.main.onError(`deletePresence API: ${error}`)
      })
  }

  @action
  async createPresenceTrait(request: CreateTraitRequest) {
    return this.service.createTrait(request)
      .then(function (presence) {
        vxm.portraitsModule.onPresenceTraits(presence);
        vxm.main.onSuccess(`特征点创建成功！`);
      }, function (error) {
        vxm.main.onError(`createTrait API: ${error}`)
      })
  }

  @action
  async updateTrait(request: UpdateTraitRequest) {
    return this.service.updateTrait(request)
      .then(function (presence) {
        vxm.portraitsModule.onPresenceTraits(presence);
        vxm.main.onSuccess(`特征点更新成功！`);
      }, function (error) {
        vxm.main.onError(`createTrait API: ${error}`)
      })
  }

  @action
  async deleteTrait(request: DeleteTraitRequest) {
    return this.service.deleteTrait(request)
      .then(function (presence) {
      vxm.portraitsModule.onPresenceTraits(presence);
      vxm.main.onSuccess(`特征点删除成功！`);
    }, function (error) {
      console.log(error)
      vxm.main.onError(`deleteTrait API: ${error}`);
    })
  }

  @mutation
  onPortraits(payload: TaxonomyPortrait[]): void {
    this.portraits = payload;
    if (router.currentRoute.name == "TaxonomyPortrait") {
      vxm.portraitsModule.onPortraitChange(+router.currentRoute.params.media);
    }
  }

  @mutation
  public onPortraitChange(mediaId: number) {
    const colorPalette = d3.scaleOrdinal(d3.schemeCategory10);
    this.currentPortraitIndex = this.portraits.findIndex(f => f.mediaId == mediaId);
    this.currentPortrait = this.portraits[this.currentPortraitIndex] || DefaultTaxonomyPortrait;

    this.currentTraits.splice(0);
    this.currentTraitColors.splice(0);

    let cnt = 0;
    this.currentPortrait.presences.forEach((presence, idx) => {
      this.currentTraits.push(...presence.traits);
      presence.traits.forEach(_ => {
        this.currentTraitColors.push(colorPalette(cnt.toString()));
      })
      cnt++;
    })
  }

  @mutation
  onPortraitUpdate(portrait: TaxonomyPortrait) {
    const index = this.portraits.findIndex(por => por.mediaId == portrait.mediaId);

    if (index != undefined && index != -1) {
      Vue.set(this.portraits, index, portrait)
    }
    vxm.portraitsModule.onPortraitChange(portrait.mediaId)
  }

  @mutation
  onPresenceTraits(presence: TaxonomyPresenceTraits) {
    const portrait = this.portraits?.find(
      f => f.mediaId == presence.media
    )

    const index = portrait?.presences.findIndex(
      s => s.taxonomy == presence.taxonomy
    );

    if (index != undefined && index != -1 && portrait) {
      Vue.set(portrait.presences, index, presence);
    }

    vxm.portraitsModule.onPortraitChange(presence.media)
  }

}
