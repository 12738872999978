/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxonomyMedia
 */
export interface TaxonomyMedia {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyMedia
     */
    mediaId: number;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyMedia
     */
    md5: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyMedia
     */
    contentType?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyMedia
     */
    originName?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyMedia
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyMedia
     */
    copyright?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyMedia
     */
    fileSize: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyMedia
     */
    duration?: number;
    /**
     * 
     * @type {Date}
     * @memberof TaxonomyMedia
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TaxonomyMedia
     */
    updatedAt: Date;
}

export function TaxonomyMediaFromJSON(json: any): TaxonomyMedia {
    return TaxonomyMediaFromJSONTyped(json, false);
}

export function TaxonomyMediaFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyMedia {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mediaId': json['mediaId'],
        'md5': json['md5'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'originName': !exists(json, 'originName') ? undefined : json['originName'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'copyright': !exists(json, 'copyright') ? undefined : json['copyright'],
        'fileSize': json['fileSize'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function TaxonomyMediaToJSON(value?: TaxonomyMedia | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mediaId': value.mediaId,
        'md5': value.md5,
        'contentType': value.contentType,
        'originName': value.originName,
        'author': value.author,
        'copyright': value.copyright,
        'fileSize': value.fileSize,
        'duration': value.duration,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


