/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuizAsset
 */
export interface QuizAsset {
    /**
     * 
     * @type {string}
     * @memberof QuizAsset
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof QuizAsset
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof QuizAsset
     */
    copyright?: string;
    /**
     * 
     * @type {number}
     * @memberof QuizAsset
     */
    presence?: number;
}

export function QuizAssetFromJSON(json: any): QuizAsset {
    return QuizAssetFromJSONTyped(json, false);
}

export function QuizAssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuizAsset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'copyright': !exists(json, 'copyright') ? undefined : json['copyright'],
        'presence': !exists(json, 'presence') ? undefined : json['presence'],
    };
}

export function QuizAssetToJSON(value?: QuizAsset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'author': value.author,
        'copyright': value.copyright,
        'presence': value.presence,
    };
}


