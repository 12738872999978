/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TaxonomyFields {
    Current = 'current',
    Ancestors = 'ancestors',
    Siblings = 'siblings',
    Children = 'children'
}

export function TaxonomyFieldsFromJSON(json: any): TaxonomyFields {
    return TaxonomyFieldsFromJSONTyped(json, false);
}

export function TaxonomyFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyFields {
    return json as TaxonomyFields;
}

export function TaxonomyFieldsToJSON(value?: TaxonomyFields | null): any {
    return value as any;
}

