import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/list/:list',
        name: 'ListPage',
        component: () => import('@/views/TaxonomyList.vue'),
        children: [
            {
                path: ':node',
                component: () => import('@/views/TaxonomyPage.vue'),
                children: [
                    {
                        path: '',
                        name: 'TaxonomyDetail',
                        component: () => import('@/views/TaxonomyDetail.vue'),
                    },
                    {
                        path: 'edit',
                        name: 'TaxonomyEdit',
                        component: () => import('@/views/TaxonomyEdit.vue'),
                    },
                    {
                        path: 'pictures',
                        name: 'TaxonomyPictures',
                        component: () => import('@/views/TaxonomyPictures.vue'),
                    },
                    {
                        path: 'portraits',
                        name: 'TaxonomyPortraits',
                        component: () => import('@/views/TaxonomyPortraits.vue'),
                        children: [
                            {
                                path: ':media',
                                name: 'TaxonomyPortrait',
                                component: () => import('@/views/TaxonomyPortrait.vue')
                            },
                        ]
                    },
                    {
                        path: 'sounds',
                        name: 'TaxonomySounds',
                        component: () => import('@/views/TaxonomyEdit.vue'),
                    },
                    {
                        path: 'actions',
                        name: 'TaxonomyActions',
                        component: () => import('@/views/TaxonomyActions.vue'),
                        children: [
                            {
                                path: 'images',
                                name: 'UploadImagePage',
                                component: () => import('@/views/actions/UploadImages.vue'),
                            },
                            {
                                path: 'portrait',
                                name: 'CreatePortraitPage',
                                component: () => import('@/views/actions/CreatePortrait.vue'),
                            },
                        ]
                    },
                ],
            }
        ]
    },

    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// router.beforeEach((to, from, next) => {
//
//   console.log('router.beforeEach', to, from);
//
//   next();
//
// });

export default router;
