// import {TaxonomyMedia} from "@/types/index";


import {TaxonomyPortrait, TaxonomyPresence, TaxonomyPresenceTraits, TaxonomyTrait} from "@/service";

export const PORTRAIT_SVG_DEFAULT_WIDTH = 1280;
export const PORTRAIT_SVG_DEFAULT_HEIGHT = 960;
export const PORTRAIT_SVG_MEASURE_DELAY = 500;

export const DefaultTaxonomyTrait: TaxonomyTrait = {
    media: 0, origin: 0, taxonomy: 0,
    chinese: "", createdAt: new Date(), marker: 0, presence: 0, traitId: 0, updatedAt: new Date()
}

export const DefaultTaxonomyPortrait: TaxonomyPortrait = {
    createdAt: new Date(),
    fileSize: 0,
    md5: "",
    mediaId: 0,
    presences: [],
    updatedAt: new Date()
};

export const DefaultTaxonomyPresence: TaxonomyPresence = {
    createdAt: new Date(), media: 0, origin: 0, presenceId: 0, taxonomy: 0, updatedAt: new Date()
};

export const DefaultTaxonomyPresenceTraits: TaxonomyPresenceTraits = {
    createdAt: new Date(),
    media: 0,
    origin: 0,
    presenceId: 0,
    taxonomy: 0,
    traits: [],
    updatedAt: new Date()
}
