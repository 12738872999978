/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxonomyTraitData
 */
export interface TaxonomyTraitData {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyTraitData
     */
    media: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyTraitData
     */
    taxonomy: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyTraitData
     */
    origin: number;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyTraitData
     */
    points?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyTraitData
     */
    chinese: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyTraitData
     */
    english?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyTraitData
     */
    traditional?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyTraitData
     */
    comments?: string;
}

export function TaxonomyTraitDataFromJSON(json: any): TaxonomyTraitData {
    return TaxonomyTraitDataFromJSONTyped(json, false);
}

export function TaxonomyTraitDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyTraitData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'media': json['media'],
        'taxonomy': json['taxonomy'],
        'origin': json['origin'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'chinese': json['chinese'],
        'english': !exists(json, 'english') ? undefined : json['english'],
        'traditional': !exists(json, 'traditional') ? undefined : json['traditional'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function TaxonomyTraitDataToJSON(value?: TaxonomyTraitData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'media': value.media,
        'taxonomy': value.taxonomy,
        'origin': value.origin,
        'points': value.points,
        'chinese': value.chinese,
        'english': value.english,
        'traditional': value.traditional,
        'comments': value.comments,
    };
}


