/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxonomyBrief
 */
export interface TaxonomyBrief {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyBrief
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyBrief
     */
    node: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyBrief
     */
    taxonomy: number;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyBrief
     */
    latin: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyBrief
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyBrief
     */
    pinyin: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyBrief
     */
    tone: string;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyBrief
     */
    kind: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyBrief
     */
    father: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyBrief
     */
    childs: number;
    /**
     * 
     * @type {Array<TaxonomyBrief>}
     * @memberof TaxonomyBrief
     */
    children?: Array<TaxonomyBrief>;
}

export function TaxonomyBriefFromJSON(json: any): TaxonomyBrief {
    return TaxonomyBriefFromJSONTyped(json, false);
}

export function TaxonomyBriefFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyBrief {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'node': json['node'],
        'taxonomy': json['taxonomy'],
        'latin': json['latin'],
        'name': json['name'],
        'pinyin': json['pinyin'],
        'tone': json['tone'],
        'kind': json['kind'],
        'father': json['father'],
        'childs': json['childs'],
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(TaxonomyBriefFromJSON)),
    };
}

export function TaxonomyBriefToJSON(value?: TaxonomyBrief | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'node': value.node,
        'taxonomy': value.taxonomy,
        'latin': value.latin,
        'name': value.name,
        'pinyin': value.pinyin,
        'tone': value.tone,
        'kind': value.kind,
        'father': value.father,
        'childs': value.childs,
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(TaxonomyBriefToJSON)),
    };
}


