/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxonomyPresenceId
 */
export interface TaxonomyPresenceId {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPresenceId
     */
    presenceId: number;
}

export function TaxonomyPresenceIdFromJSON(json: any): TaxonomyPresenceId {
    return TaxonomyPresenceIdFromJSONTyped(json, false);
}

export function TaxonomyPresenceIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyPresenceId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presenceId': json['presenceId'],
    };
}

export function TaxonomyPresenceIdToJSON(value?: TaxonomyPresenceId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'presenceId': value.presenceId,
    };
}


