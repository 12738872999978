import {Taxonomy, TaxonomyBrief, TaxonomyList, TaxonomyPage} from "@/service";

export interface TaxonomyQuery {
    list: number;
    node: number;
    target: TaxonomyBrief
}

export const DefaultTaxonomyList: TaxonomyList = {
    chinese: "",
    code: "",
    english: "",
    id: 0,
    node: 0,
    publisher: "",
    purpose: "",
    reference: 0,
    released: "",
    traditional: "",
    url: "",
    version: ""
}

export function castTaxonomyToBrief(from: Taxonomy | undefined): TaxonomyBrief {
    return (from == undefined) ? DefaultTaxonomyBrief : {
        name: from.chinese ? from.chinese : from.latin,
        pinyin: from.chinesePinyin || "", tone: from.chineseTone || "",
        id: from.id, childs: from.childs, father: from.father,
        kind: from.kind, latin: from.latin,
        node: from.node, taxonomy: from.taxonomy
    }
}

export const DefaultTaxonomyBrief: TaxonomyBrief = {
    name: "", pinyin: "", tone: "",
    id: 0, childs: 0, father: 0, kind: 0, latin: "", node: 0, taxonomy: 0
}

export const DefaultTaxonomy: Taxonomy = {
    id: 0, childs: 0, father: 0, kind: 0, latin: "", list: 0, node: 0, taxonomy: 0
}

export const DefaultTaxonomyPage: TaxonomyPage = {
    current: DefaultTaxonomy,
    ancestors: [],
};
