import {action, createModule} from "vuex-class-component";
import {Configuration, DefaultApi, ListImagesRequest, TaxonomyMedia} from "@/service";
import {vxm} from "@/store/index";

const VuexModule = createModule({
  namespaced: "mediaStore",
  strict: false,
  // target: "nuxt",
})


export class MediaStore extends VuexModule {

  public data = {
    images: [] as TaxonomyMedia[]
  }

  private service = new DefaultApi(new Configuration({
    basePath: process.env.VUE_APP_SERVICE_BASE_URL,
  }));

  @action
  async listNodeImages(params: ListImagesRequest) {
    console.log('MediaStore.listNodeImages', params)
    return this.service.listImages(params)
      .then(function (images) {
        vxm.mediaModule.data.images = images;
      }, function (error) {
        vxm.main.onError(`listNodeImages API: ${error}`)
      })
  }

}
