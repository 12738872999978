/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TaxonomyTraitAllOf,
    TaxonomyTraitAllOfFromJSON,
    TaxonomyTraitAllOfFromJSONTyped,
    TaxonomyTraitAllOfToJSON,
    TaxonomyTraitData,
    TaxonomyTraitDataFromJSON,
    TaxonomyTraitDataFromJSONTyped,
    TaxonomyTraitDataToJSON,
    TaxonomyTraitId,
    TaxonomyTraitIdFromJSON,
    TaxonomyTraitIdFromJSONTyped,
    TaxonomyTraitIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface TaxonomyTrait
 */
export interface TaxonomyTrait {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyTrait
     */
    traitId: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyTrait
     */
    media: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyTrait
     */
    taxonomy: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyTrait
     */
    origin: number;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyTrait
     */
    points?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyTrait
     */
    chinese: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyTrait
     */
    english?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyTrait
     */
    traditional?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyTrait
     */
    comments?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyTrait
     */
    presence: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyTrait
     */
    marker: number;
    /**
     * 
     * @type {Date}
     * @memberof TaxonomyTrait
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TaxonomyTrait
     */
    updatedAt: Date;
}

export function TaxonomyTraitFromJSON(json: any): TaxonomyTrait {
    return TaxonomyTraitFromJSONTyped(json, false);
}

export function TaxonomyTraitFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyTrait {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'traitId': json['traitId'],
        'media': json['media'],
        'taxonomy': json['taxonomy'],
        'origin': json['origin'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'chinese': json['chinese'],
        'english': !exists(json, 'english') ? undefined : json['english'],
        'traditional': !exists(json, 'traditional') ? undefined : json['traditional'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'presence': json['presence'],
        'marker': json['marker'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function TaxonomyTraitToJSON(value?: TaxonomyTrait | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'traitId': value.traitId,
        'media': value.media,
        'taxonomy': value.taxonomy,
        'origin': value.origin,
        'points': value.points,
        'chinese': value.chinese,
        'english': value.english,
        'traditional': value.traditional,
        'comments': value.comments,
        'presence': value.presence,
        'marker': value.marker,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


