/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxonomyList
 */
export interface TaxonomyList {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyList
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyList
     */
    node: number;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyList
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyList
     */
    reference: number;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyList
     */
    purpose: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyList
     */
    publisher?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyList
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyList
     */
    released?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyList
     */
    english?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyList
     */
    chinese?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyList
     */
    traditional?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyList
     */
    url?: string;
}

export function TaxonomyListFromJSON(json: any): TaxonomyList {
    return TaxonomyListFromJSONTyped(json, false);
}

export function TaxonomyListFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'node': json['node'],
        'code': json['code'],
        'reference': json['reference'],
        'purpose': json['purpose'],
        'publisher': !exists(json, 'publisher') ? undefined : json['publisher'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'released': !exists(json, 'released') ? undefined : json['released'],
        'english': !exists(json, 'english') ? undefined : json['english'],
        'chinese': !exists(json, 'chinese') ? undefined : json['chinese'],
        'traditional': !exists(json, 'traditional') ? undefined : json['traditional'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function TaxonomyListToJSON(value?: TaxonomyList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'node': value.node,
        'code': value.code,
        'reference': value.reference,
        'purpose': value.purpose,
        'publisher': value.publisher,
        'version': value.version,
        'released': value.released,
        'english': value.english,
        'chinese': value.chinese,
        'traditional': value.traditional,
        'url': value.url,
    };
}


