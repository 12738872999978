/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TaxonomyPresenceDataAllOf,
    TaxonomyPresenceDataAllOfFromJSON,
    TaxonomyPresenceDataAllOfFromJSONTyped,
    TaxonomyPresenceDataAllOfToJSON,
    TaxonomyPresenceId,
    TaxonomyPresenceIdFromJSON,
    TaxonomyPresenceIdFromJSONTyped,
    TaxonomyPresenceIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface TaxonomyPresenceData
 */
export interface TaxonomyPresenceData {
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPresenceData
     */
    presenceId: number;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyPresenceData
     */
    scope?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxonomyPresenceData
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPresenceData
     */
    origin: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPresenceData
     */
    media: number;
    /**
     * 
     * @type {number}
     * @memberof TaxonomyPresenceData
     */
    taxonomy: number;
}

export function TaxonomyPresenceDataFromJSON(json: any): TaxonomyPresenceData {
    return TaxonomyPresenceDataFromJSONTyped(json, false);
}

export function TaxonomyPresenceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxonomyPresenceData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presenceId': json['presenceId'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'origin': json['origin'],
        'media': json['media'],
        'taxonomy': json['taxonomy'],
    };
}

export function TaxonomyPresenceDataToJSON(value?: TaxonomyPresenceData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'presenceId': value.presenceId,
        'scope': value.scope,
        'note': value.note,
        'origin': value.origin,
        'media': value.media,
        'taxonomy': value.taxonomy,
    };
}


