/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    QuizPaper,
    QuizPaperFromJSON,
    QuizPaperToJSON,
    QuizReference,
    QuizReferenceFromJSON,
    QuizReferenceToJSON,
    QuizSubject,
    QuizSubjectFromJSON,
    QuizSubjectToJSON,
    Taxonomy,
    TaxonomyFromJSON,
    TaxonomyToJSON,
    TaxonomyBrief,
    TaxonomyBriefFromJSON,
    TaxonomyBriefToJSON,
    TaxonomyData,
    TaxonomyDataFromJSON,
    TaxonomyDataToJSON,
    TaxonomyList,
    TaxonomyListFromJSON,
    TaxonomyListToJSON,
    TaxonomyMedia,
    TaxonomyMediaFromJSON,
    TaxonomyMediaToJSON,
    TaxonomyPage,
    TaxonomyPageFromJSON,
    TaxonomyPageToJSON,
    TaxonomyPortrait,
    TaxonomyPortraitFromJSON,
    TaxonomyPortraitToJSON,
    TaxonomyPortraitNode,
    TaxonomyPortraitNodeFromJSON,
    TaxonomyPortraitNodeToJSON,
    TaxonomyPresenceData,
    TaxonomyPresenceDataFromJSON,
    TaxonomyPresenceDataToJSON,
    TaxonomyPresenceId,
    TaxonomyPresenceIdFromJSON,
    TaxonomyPresenceIdToJSON,
    TaxonomyPresenceTraits,
    TaxonomyPresenceTraitsFromJSON,
    TaxonomyPresenceTraitsToJSON,
    TaxonomyTraitData,
    TaxonomyTraitDataFromJSON,
    TaxonomyTraitDataToJSON,
} from '../models';

export interface CreatePresenceRequest {
    listId: number;
    mediaId: number;
    taxonomyPresenceData: TaxonomyPresenceData;
}

export interface CreateTraitRequest {
    listId: number;
    taxonomyTraitData: TaxonomyTraitData;
}

export interface DeletePresenceRequest {
    listId: number;
    mediaId: number;
    taxonomyPresenceId: TaxonomyPresenceId;
}

export interface DeleteTraitRequest {
    listId: number;
    traitId: number;
    taxonomyTraitData: TaxonomyTraitData;
}

export interface GenerateQuizPaperRequest {
    level: number;
}

export interface GetListDataRequest {
    listId: number;
}

export interface GetMediaPortraitRequest {
    listId: number;
    mediaId: number;
}

export interface GetPresenceWithTraitsRequest {
    listId: number;
    nodeId: number;
    mediaId: number;
    taxonomyId: number;
}

export interface GetQuizReferenceRequest {
    quizId: number;
}

export interface GetQuizSubjectRequest {
    quizId: number;
}

export interface ListAllSpeciesRequest {
    listId: number;
}

export interface ListImagesRequest {
    listId: number;
    nodeId: number;
}

export interface ListNodePortraitsRequest {
    listId: number;
    nodeId: number;
}

export interface ListPortraitsIdRequest {
    listId: number;
}

export interface ListTaxonomyPortraitsRequest {
    listId: number;
    taxonomyId: number;
}

export interface LoadTaxonomyRequest {
    listId: number;
    nodeId: number;
    fields: Array<string>;
    limit?: number;
}

export interface UpdateTaxonomyRequest {
    listId: number;
    nodeId: number;
    taxonomyData: TaxonomyData;
}

export interface UpdateTraitRequest {
    listId: number;
    traitId: number;
    taxonomyTraitData: TaxonomyTraitData;
}

export interface UploadImageRequest {
    listId: number;
    nodeId: number;
    file?: Blob;
    author?: Array<string>;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create A portrait from a existing media.
     */
    async createPresenceRaw(requestParameters: CreatePresenceRequest): Promise<runtime.ApiResponse<TaxonomyPortrait>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling createPresence.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling createPresence.');
        }

        if (requestParameters.taxonomyPresenceData === null || requestParameters.taxonomyPresenceData === undefined) {
            throw new runtime.RequiredError('taxonomyPresenceData','Required parameter requestParameters.taxonomyPresenceData was null or undefined when calling createPresence.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tree/{listId}/portraits/{mediaId}`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaxonomyPresenceDataToJSON(requestParameters.taxonomyPresenceData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyPortraitFromJSON(jsonValue));
    }

    /**
     * Create A portrait from a existing media.
     */
    async createPresence(requestParameters: CreatePresenceRequest): Promise<TaxonomyPortrait> {
        const response = await this.createPresenceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create A trait for species in certain media.
     */
    async createTraitRaw(requestParameters: CreateTraitRequest): Promise<runtime.ApiResponse<TaxonomyPresenceTraits>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling createTrait.');
        }

        if (requestParameters.taxonomyTraitData === null || requestParameters.taxonomyTraitData === undefined) {
            throw new runtime.RequiredError('taxonomyTraitData','Required parameter requestParameters.taxonomyTraitData was null or undefined when calling createTrait.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tree/{listId}/traits`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaxonomyTraitDataToJSON(requestParameters.taxonomyTraitData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyPresenceTraitsFromJSON(jsonValue));
    }

    /**
     * Create A trait for species in certain media.
     */
    async createTrait(requestParameters: CreateTraitRequest): Promise<TaxonomyPresenceTraits> {
        const response = await this.createTraitRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete a presence
     */
    async deletePresenceRaw(requestParameters: DeletePresenceRequest): Promise<runtime.ApiResponse<TaxonomyPortrait>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling deletePresence.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling deletePresence.');
        }

        if (requestParameters.taxonomyPresenceId === null || requestParameters.taxonomyPresenceId === undefined) {
            throw new runtime.RequiredError('taxonomyPresenceId','Required parameter requestParameters.taxonomyPresenceId was null or undefined when calling deletePresence.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tree/{listId}/portraits/{mediaId}`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: TaxonomyPresenceIdToJSON(requestParameters.taxonomyPresenceId),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyPortraitFromJSON(jsonValue));
    }

    /**
     * delete a presence
     */
    async deletePresence(requestParameters: DeletePresenceRequest): Promise<TaxonomyPortrait> {
        const response = await this.deletePresenceRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete a traitId
     */
    async deleteTraitRaw(requestParameters: DeleteTraitRequest): Promise<runtime.ApiResponse<TaxonomyPresenceTraits>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling deleteTrait.');
        }

        if (requestParameters.traitId === null || requestParameters.traitId === undefined) {
            throw new runtime.RequiredError('traitId','Required parameter requestParameters.traitId was null or undefined when calling deleteTrait.');
        }

        if (requestParameters.taxonomyTraitData === null || requestParameters.taxonomyTraitData === undefined) {
            throw new runtime.RequiredError('taxonomyTraitData','Required parameter requestParameters.taxonomyTraitData was null or undefined when calling deleteTrait.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tree/{listId}/traits/{traitId}`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"traitId"}}`, encodeURIComponent(String(requestParameters.traitId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: TaxonomyTraitDataToJSON(requestParameters.taxonomyTraitData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyPresenceTraitsFromJSON(jsonValue));
    }

    /**
     * delete a traitId
     */
    async deleteTrait(requestParameters: DeleteTraitRequest): Promise<TaxonomyPresenceTraits> {
        const response = await this.deleteTraitRaw(requestParameters);
        return await response.value();
    }

    /**
     * get a quiz
     */
    async generateQuizPaperRaw(requestParameters: GenerateQuizPaperRequest): Promise<runtime.ApiResponse<QuizPaper>> {
        if (requestParameters.level === null || requestParameters.level === undefined) {
            throw new runtime.RequiredError('level','Required parameter requestParameters.level was null or undefined when calling generateQuizPaper.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/quiz/paper/{level}`.replace(`{${"level"}}`, encodeURIComponent(String(requestParameters.level))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuizPaperFromJSON(jsonValue));
    }

    /**
     * get a quiz
     */
    async generateQuizPaper(requestParameters: GenerateQuizPaperRequest): Promise<QuizPaper> {
        const response = await this.generateQuizPaperRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns all taxonomy as a tree structure. 
     * Returns all taxonomy as a tree structure.
     */
    async getListDataRaw(requestParameters: GetListDataRequest): Promise<runtime.ApiResponse<TaxonomyList>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling getListData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/{listId}`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyListFromJSON(jsonValue));
    }

    /**
     * Returns all taxonomy as a tree structure. 
     * Returns all taxonomy as a tree structure.
     */
    async getListData(requestParameters: GetListDataRequest): Promise<TaxonomyList> {
        const response = await this.getListDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * get the all traits of species in certain media.
     */
    async getMediaPortraitRaw(requestParameters: GetMediaPortraitRequest): Promise<runtime.ApiResponse<TaxonomyPortrait>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling getMediaPortrait.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling getMediaPortrait.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/{listId}/portraits/{mediaId}`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyPortraitFromJSON(jsonValue));
    }

    /**
     * get the all traits of species in certain media.
     */
    async getMediaPortrait(requestParameters: GetMediaPortraitRequest): Promise<TaxonomyPortrait> {
        const response = await this.getMediaPortraitRaw(requestParameters);
        return await response.value();
    }

    /**
     * list all traits of species in certain media.
     */
    async getPresenceWithTraitsRaw(requestParameters: GetPresenceWithTraitsRequest): Promise<runtime.ApiResponse<TaxonomyPresenceTraits>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling getPresenceWithTraits.');
        }

        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getPresenceWithTraits.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling getPresenceWithTraits.');
        }

        if (requestParameters.taxonomyId === null || requestParameters.taxonomyId === undefined) {
            throw new runtime.RequiredError('taxonomyId','Required parameter requestParameters.taxonomyId was null or undefined when calling getPresenceWithTraits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/{listId}/{nodeId}/portraits/{mediaId}/{taxonomyId}`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))).replace(`{${"taxonomyId"}}`, encodeURIComponent(String(requestParameters.taxonomyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyPresenceTraitsFromJSON(jsonValue));
    }

    /**
     * list all traits of species in certain media.
     */
    async getPresenceWithTraits(requestParameters: GetPresenceWithTraitsRequest): Promise<TaxonomyPresenceTraits> {
        const response = await this.getPresenceWithTraitsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get all references of a quiz
     */
    async getQuizReferenceRaw(requestParameters: GetQuizReferenceRequest): Promise<runtime.ApiResponse<Array<QuizReference>>> {
        if (requestParameters.quizId === null || requestParameters.quizId === undefined) {
            throw new runtime.RequiredError('quizId','Required parameter requestParameters.quizId was null or undefined when calling getQuizReference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/quiz/reference/{quizId}`.replace(`{${"quizId"}}`, encodeURIComponent(String(requestParameters.quizId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QuizReferenceFromJSON));
    }

    /**
     * get all references of a quiz
     */
    async getQuizReference(requestParameters: GetQuizReferenceRequest): Promise<Array<QuizReference>> {
        const response = await this.getQuizReferenceRaw(requestParameters);
        return await response.value();
    }

    /**
     * get a quiz subject
     */
    async getQuizSubjectRaw(requestParameters: GetQuizSubjectRequest): Promise<runtime.ApiResponse<QuizSubject>> {
        if (requestParameters.quizId === null || requestParameters.quizId === undefined) {
            throw new runtime.RequiredError('quizId','Required parameter requestParameters.quizId was null or undefined when calling getQuizSubject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/quiz/{quizId}`.replace(`{${"quizId"}}`, encodeURIComponent(String(requestParameters.quizId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuizSubjectFromJSON(jsonValue));
    }

    /**
     * get a quiz subject
     */
    async getQuizSubject(requestParameters: GetQuizSubjectRequest): Promise<QuizSubject> {
        const response = await this.getQuizSubjectRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns all taxonomy as a tree structure. 
     * Returns all taxonomy as a tree structure.
     */
    async listAllSpeciesRaw(requestParameters: ListAllSpeciesRequest): Promise<runtime.ApiResponse<Array<TaxonomyBrief>>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling listAllSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/{listId}/species`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaxonomyBriefFromJSON));
    }

    /**
     * Returns all taxonomy as a tree structure. 
     * Returns all taxonomy as a tree structure.
     */
    async listAllSpecies(requestParameters: ListAllSpeciesRequest): Promise<Array<TaxonomyBrief>> {
        const response = await this.listAllSpeciesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list images of species.
     */
    async listImagesRaw(requestParameters: ListImagesRequest): Promise<runtime.ApiResponse<Array<TaxonomyMedia>>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling listImages.');
        }

        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling listImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/{listId}/{nodeId}/images`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaxonomyMediaFromJSON));
    }

    /**
     * list images of species.
     */
    async listImages(requestParameters: ListImagesRequest): Promise<Array<TaxonomyMedia>> {
        const response = await this.listImagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * list all features of a node.
     */
    async listNodePortraitsRaw(requestParameters: ListNodePortraitsRequest): Promise<runtime.ApiResponse<Array<TaxonomyPortrait>>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling listNodePortraits.');
        }

        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling listNodePortraits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/{listId}/{nodeId}/portraits`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaxonomyPortraitFromJSON));
    }

    /**
     * list all features of a node.
     */
    async listNodePortraits(requestParameters: ListNodePortraitsRequest): Promise<Array<TaxonomyPortrait>> {
        const response = await this.listNodePortraitsRaw(requestParameters);
        return await response.value();
    }

    /**
     * list all portraits Id of a list.
     */
    async listPortraitsIdRaw(requestParameters: ListPortraitsIdRequest): Promise<runtime.ApiResponse<Array<TaxonomyPortraitNode>>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling listPortraitsId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/{listId}/portraits`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaxonomyPortraitNodeFromJSON));
    }

    /**
     * list all portraits Id of a list.
     */
    async listPortraitsId(requestParameters: ListPortraitsIdRequest): Promise<Array<TaxonomyPortraitNode>> {
        const response = await this.listPortraitsIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * list all features of a node.
     */
    async listTaxonomyPortraitsRaw(requestParameters: ListTaxonomyPortraitsRequest): Promise<runtime.ApiResponse<Array<TaxonomyPortrait>>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling listTaxonomyPortraits.');
        }

        if (requestParameters.taxonomyId === null || requestParameters.taxonomyId === undefined) {
            throw new runtime.RequiredError('taxonomyId','Required parameter requestParameters.taxonomyId was null or undefined when calling listTaxonomyPortraits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/{listId}/portraits/taxonomy/{taxonomyId}`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"taxonomyId"}}`, encodeURIComponent(String(requestParameters.taxonomyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaxonomyPortraitFromJSON));
    }

    /**
     * list all features of a node.
     */
    async listTaxonomyPortraits(requestParameters: ListTaxonomyPortraitsRequest): Promise<Array<TaxonomyPortrait>> {
        const response = await this.listTaxonomyPortraitsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a taxonomy\' brief. 
     * Returns a taxonomy\' brief.
     */
    async loadTaxonomyRaw(requestParameters: LoadTaxonomyRequest): Promise<runtime.ApiResponse<TaxonomyPage>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling loadTaxonomy.');
        }

        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling loadTaxonomy.');
        }

        if (requestParameters.fields === null || requestParameters.fields === undefined) {
            throw new runtime.RequiredError('fields','Required parameter requestParameters.fields was null or undefined when calling loadTaxonomy.');
        }

        const queryParameters: any = {};

        if (requestParameters.fields) {
            queryParameters['fields'] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tree/{listId}/{nodeId}`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyPageFromJSON(jsonValue));
    }

    /**
     * Returns a taxonomy\' brief. 
     * Returns a taxonomy\' brief.
     */
    async loadTaxonomy(requestParameters: LoadTaxonomyRequest): Promise<TaxonomyPage> {
        const response = await this.loadTaxonomyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update taxonomy data. 
     * Update taxonomy data.
     */
    async updateTaxonomyRaw(requestParameters: UpdateTaxonomyRequest): Promise<runtime.ApiResponse<Taxonomy>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling updateTaxonomy.');
        }

        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling updateTaxonomy.');
        }

        if (requestParameters.taxonomyData === null || requestParameters.taxonomyData === undefined) {
            throw new runtime.RequiredError('taxonomyData','Required parameter requestParameters.taxonomyData was null or undefined when calling updateTaxonomy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tree/{listId}/{nodeId}`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TaxonomyDataToJSON(requestParameters.taxonomyData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyFromJSON(jsonValue));
    }

    /**
     * Update taxonomy data. 
     * Update taxonomy data.
     */
    async updateTaxonomy(requestParameters: UpdateTaxonomyRequest): Promise<Taxonomy> {
        const response = await this.updateTaxonomyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create A trait for species in certain media.
     */
    async updateTraitRaw(requestParameters: UpdateTraitRequest): Promise<runtime.ApiResponse<TaxonomyPresenceTraits>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling updateTrait.');
        }

        if (requestParameters.traitId === null || requestParameters.traitId === undefined) {
            throw new runtime.RequiredError('traitId','Required parameter requestParameters.traitId was null or undefined when calling updateTrait.');
        }

        if (requestParameters.taxonomyTraitData === null || requestParameters.taxonomyTraitData === undefined) {
            throw new runtime.RequiredError('taxonomyTraitData','Required parameter requestParameters.taxonomyTraitData was null or undefined when calling updateTrait.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tree/{listId}/traits/{traitId}`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"traitId"}}`, encodeURIComponent(String(requestParameters.traitId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TaxonomyTraitDataToJSON(requestParameters.taxonomyTraitData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyPresenceTraitsFromJSON(jsonValue));
    }

    /**
     * Create A trait for species in certain media.
     */
    async updateTrait(requestParameters: UpdateTraitRequest): Promise<TaxonomyPresenceTraits> {
        const response = await this.updateTraitRaw(requestParameters);
        return await response.value();
    }

    /**
     * Uploads a file.
     */
    async uploadImageRaw(requestParameters: UploadImageRequest): Promise<runtime.ApiResponse<TaxonomyMedia>> {
        if (requestParameters.listId === null || requestParameters.listId === undefined) {
            throw new runtime.RequiredError('listId','Required parameter requestParameters.listId was null or undefined when calling uploadImage.');
        }

        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling uploadImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.author) {
            formParams.append('author', requestParameters.author.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        const response = await this.request({
            path: `/tree/{listId}/{nodeId}/upload`.replace(`{${"listId"}}`, encodeURIComponent(String(requestParameters.listId))).replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyMediaFromJSON(jsonValue));
    }

    /**
     * Uploads a file.
     */
    async uploadImage(requestParameters: UploadImageRequest): Promise<TaxonomyMedia> {
        const response = await this.uploadImageRaw(requestParameters);
        return await response.value();
    }

}
