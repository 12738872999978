/* tslint:disable */
/* eslint-disable */
/**
 * Wings Server
 * A backend for birds database.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: li3p@xmbirds.cn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TaxonomyAllOf,
    TaxonomyAllOfFromJSON,
    TaxonomyAllOfFromJSONTyped,
    TaxonomyAllOfToJSON,
    TaxonomyData,
    TaxonomyDataFromJSON,
    TaxonomyDataFromJSONTyped,
    TaxonomyDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface Taxonomy
 */
export interface Taxonomy {
    /**
     * 
     * @type {number}
     * @memberof Taxonomy
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Taxonomy
     */
    list: number;
    /**
     * 
     * @type {number}
     * @memberof Taxonomy
     */
    node: number;
    /**
     * 
     * @type {number}
     * @memberof Taxonomy
     */
    taxonomy: number;
    /**
     * 
     * @type {number}
     * @memberof Taxonomy
     */
    originalId?: number;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    latin: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    english?: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    chinese?: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    traditional?: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    authority?: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    aliasId?: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    endangered?: string;
    /**
     * 
     * @type {number}
     * @memberof Taxonomy
     */
    childs: number;
    /**
     * 
     * @type {number}
     * @memberof Taxonomy
     */
    kind: number;
    /**
     * 
     * @type {number}
     * @memberof Taxonomy
     */
    father: number;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    chinesePinyin?: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    traditionalPinyin?: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    chineseTone?: string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    traditionalTone?: string;
}

export function TaxonomyFromJSON(json: any): Taxonomy {
    return TaxonomyFromJSONTyped(json, false);
}

export function TaxonomyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Taxonomy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'list': json['list'],
        'node': json['node'],
        'taxonomy': json['taxonomy'],
        'originalId': !exists(json, 'originalId') ? undefined : json['originalId'],
        'latin': json['latin'],
        'english': !exists(json, 'english') ? undefined : json['english'],
        'chinese': !exists(json, 'chinese') ? undefined : json['chinese'],
        'traditional': !exists(json, 'traditional') ? undefined : json['traditional'],
        'authority': !exists(json, 'authority') ? undefined : json['authority'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'aliasId': !exists(json, 'aliasId') ? undefined : json['aliasId'],
        'endangered': !exists(json, 'endangered') ? undefined : json['endangered'],
        'childs': json['childs'],
        'kind': json['kind'],
        'father': json['father'],
        'chinesePinyin': !exists(json, 'chinesePinyin') ? undefined : json['chinesePinyin'],
        'traditionalPinyin': !exists(json, 'traditionalPinyin') ? undefined : json['traditionalPinyin'],
        'chineseTone': !exists(json, 'chineseTone') ? undefined : json['chineseTone'],
        'traditionalTone': !exists(json, 'traditionalTone') ? undefined : json['traditionalTone'],
    };
}

export function TaxonomyToJSON(value?: Taxonomy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'list': value.list,
        'node': value.node,
        'taxonomy': value.taxonomy,
        'originalId': value.originalId,
        'latin': value.latin,
        'english': value.english,
        'chinese': value.chinese,
        'traditional': value.traditional,
        'authority': value.authority,
        'url': value.url,
        'code': value.code,
        'note': value.note,
        'aliasId': value.aliasId,
        'endangered': value.endangered,
        'childs': value.childs,
        'kind': value.kind,
        'father': value.father,
        'chinesePinyin': value.chinesePinyin,
        'traditionalPinyin': value.traditionalPinyin,
        'chineseTone': value.chineseTone,
        'traditionalTone': value.traditionalTone,
    };
}


